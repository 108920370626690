import React, { FC, useEffect, useState } from "react"
import { RegisterOptions, useFormContext } from "react-hook-form"
import "./dropdown.css"

interface DropdownProps {
	inputName: string
	description: string
	validator?: RegisterOptions
	fieldWidth?: number
	isArray?: boolean
	defaultMessage?: string
	options: string[]
	freetext?: string
	arrayIndex?: number
}

const DropdownMemo: FC<DropdownProps> = (props: DropdownProps) => {
	const {
		description,
		inputName,
		validator,
		fieldWidth,
		options,
		isArray,
		defaultMessage,
		freetext,
		arrayIndex,
	} = props
	const {
		formState: { errors },
		register,
		trigger,
		setValue,
	} = useFormContext<{ [key: string]: any }>()

	const [expanded, setExpanded] = useState(false)
	const [selected, setSelected] = useState(undefined as string)
	const realInputName = `${inputName}${isArray ? `[${arrayIndex}]` : ""}`
	function handleChange(selectedIndex: string) {
		setExpanded(false)
		setSelected(selectedIndex)
		setValue(realInputName, selectedIndex)
		trigger(realInputName)
	}

	const [dropdownValues, setDropdownValues] = useState(options as [])

	return (
		<div className={`col-12 col-md-${fieldWidth}`}>
			<div
				className={`field-group select-replace ${
					(isArray &&
						errors &&
						errors[inputName] &&
						errors[inputName][arrayIndex]) ||
					(!isArray && errors && errors[inputName])
						? "error"
						: ""
				} `}
				data-element="form-element"
				data-no-js="true"
			>
				<label htmlFor={inputName} className="required">
					{description}
				</label>
				<div className="field-group__container field-group__container--has-inner">
					<label className="form-control__container">
						<select
							{...register(realInputName, validator)}
							className="form-control__field"
							id={realInputName}
							name={!(freetext && selected === freetext) ? realInputName : ""}
							aria-expanded={expanded ? true : undefined}
							defaultValue={defaultMessage}
							onChange={(e) => handleChange(e.target.value)}
						>
							<option data-value={undefined} value={undefined} disabled>
								{defaultMessage}
							</option>
							{dropdownValues &&
								dropdownValues.map((value: string, index) => (
									<option
										data-value={value}
										value={value}
										key={value}
										data-key={index}
										selected={selected === value ? true : undefined}
									>
										{value}
									</option>
								))}
						</select>
						<div
							id={`${inputName}-value`}
							data-value={selected}
							className="select-replace-container form-control__field"
							onClick={() => setExpanded(!expanded)}
						>
							{selected || "Bitte wählen"}
						</div>
						<div className="custom-options">
							<div className="custom-options-wrapper">
								{dropdownValues &&
									dropdownValues.map((value: string, index: number) => (
										<div
											className="option"
											data-value={value}
											data-key={index}
											key={value}
											onClick={() => handleChange(value)}
										>
											{value}
										</div>
									))}
							</div>
						</div>
						<div className="form-control__btns">
							<button
								type="button"
								disabled={false}
								className="trigger select-trigger"
								onClick={() => setExpanded(!expanded)}
							>
								<div className="icon icon-dropdown" />
							</button>
						</div>
					</label>
				</div>
				{errors && isArray && errors[inputName] && errors[inputName][arrayIndex] && (
					<span className="error-block">
						{errors[inputName][arrayIndex].type === "required"
							? "Die Eingabe wird benötigt"
							: ""}
						{errors[inputName][arrayIndex].type === "pattern"
							? errors[inputName][arrayIndex].message
							: ""}
						{errors[inputName][arrayIndex].type === "validate"
							? errors[inputName][arrayIndex].message
							: ""}
					</span>
				)}
				{errors && !isArray && errors[inputName] && (
					<span className="error-block">
						{errors[inputName].type === "required"
							? "Die Eingabe wird benötigt"
							: ""}
						{errors[inputName].type === "pattern" ? errors[inputName].message : ""}
						{errors[inputName].type === "validate" ? errors[inputName].message : ""}
					</span>
				)}
				{freetext && selected === freetext && (
					<div
						className={`field-group ${errors && errors[inputName] ? "error" : ""} `}
					>
						<label htmlFor={inputName} className="required">
							Sonstiges
						</label>
						<div className="field-group__container field-group__container--has-inner">
							<div className="form-control__container">
								<input
									id={inputName}
									name={`${realInputName}`}
									type="text"
									className="form-control__field"
									autoComplete="off"
									defaultValue=""
								/>
								<div className="form-control__btns" />
							</div>
							<div className="field-group__btns " />
						</div>
						{errors && errors[inputName] && (
							<span className="error-block">
								{errors[inputName].type === "required"
									? "Die Eingabe wird benötigt"
									: ""}
								{errors[inputName].type === "pattern"
									? errors[inputName].message
									: ""}
								{errors[inputName].type === "validate"
									? errors[inputName].message
									: ""}
								{errors[inputName].type === "maxLength"
									? "Die Eingabe ist zu lang"
									: ""}
							</span>
						)}
					</div>
				)}
			</div>
		</div>
	)
}

export const Dropdown = React.memo(DropdownMemo)

DropdownMemo.defaultProps = {
	fieldWidth: 12,
	validator: {
		validate: (value) =>
			value !== "Bitte wählen" ? true : "Bitte treffen Sie ein auswahl",
	},
	isArray: false,
	defaultMessage: "Bitte wählen",
	freetext: undefined,
	arrayIndex: undefined,
}
