import React, { FC } from "react"
import { Document, Page, pdfjs } from "react-pdf"
import "./pdf.css"

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`
interface PdfPreviewProps {
	file: any
}
const PdfPreviewMemo: FC<PdfPreviewProps> = (props: PdfPreviewProps) => {
	const { file } = props

	return (
		<Document file={URL.createObjectURL(file)}>
			<Page pageNumber={1} renderAnnotationLayer={false} renderTextLayer={false}/>
		</Document>
	)
}
export const PdfPreview = React.memo(PdfPreviewMemo)
